<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>organic-plant-grow</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M20.251,7.585A8.283,8.283,0,0,0,12,15.9,8.283,8.283,0,0,0,20.251,7.585Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.171,10.279A9.238,9.238,0,0,0,12.011.751a9.238,9.238,0,0,0-2.147,9.565"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.751,7.585A8.283,8.283,0,0,1,12,15.9,8.283,8.283,0,0,1,3.751,7.585Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.001"
      y1="19.501"
      x2="12.001"
      y2="15.9"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M.751,23.25a18.754,18.754,0,0,1,22.5,0"
    />
  </svg>
</template>
